import { Link, StaticQuery, graphql } from 'gatsby'
import React, { useState } from "react"

import { pathTo } from '../../routes'
import Container from '../container'
import Modal from '../modal'
import HeaderFormLogin from './form'

import logo from '../../assets/images/logo_quadrat.jpg'
import * as styles from './index.module.scss'

const Header = () => {
  const [showNavigation, setShowNavigation] = useState(false)

  return (
    <header className={styles.wrapper}>
      <div className={styles.topbar}>
        <Container>
          <div className={styles.aligner}>
            <div className={styles.numbers}>
              <div className={styles.number}>
                Chemnitz: <a href="tel:+49371381550">+49 371 / 38 155 0</a>
              </div>
              <div className={styles.number}>
                Dresden: <a href="tel:+49371381550">+49 351 / 811 97 500</a>
              </div>
            </div>
            <Link className={styles.modalToggle} to="https://portal.advice-steuerberatung.de/">Login</Link>
          </div>
        </Container>
      </div>
      <div className={styles.header}>
        <Container>
          <div className={styles.row}>
            <div className={styles.logo}>
              <Link to='/'>
                <img
                  src={logo}
                  alt='Advice - Steuerberatung in Dresden'
                  title="Advice - Steuerberatung in Dresden"
                />
              </Link>
            </div>
            <button className={styles.toggle} data-active={showNavigation} onClick={() => setShowNavigation(!showNavigation)} tabIndex="-1">
              <div className={styles.bar} />
              <div className={styles.bar} />
              <div className={styles.bar} />
            </button>
            <div className={styles.navigation} data-active={showNavigation}>
              <StaticQuery
                query={graphql`
                  query MainNavigationQuery {
                    main: contentfulNavigationZone(title: {eq: "Main"}) {
                      items {
                        __typename
                        ... on ContentfulPage {
                          id
                          slug
                          title
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  if (data === undefined || data.main === undefined) return null

                  return [...data.main.items].map(item => (
                    <Link
                      key={item.id}
                      to={pathTo(item)}
                      className={styles.link}
                      activeClassName={styles.active}
                      onClick={() => setShowNavigation(false)}
                    >{item.title}</Link>
                  ))
                }}
              />
            </div>
          </div>
        </Container>
      </div>
    </header>
  )
}

export default Header
